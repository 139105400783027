import React, {MouseEventHandler, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Outlet, Routes, Route } from 'react-router-dom';
import '../style/researcher/index.css'
import '../style/main.css'
import '../style/welcome.css'
import Navbar from "../components/researcher/Navbar";
import Researcher from "./resercher";
import Management from './management'
import Welcome from './Welcome'
import rest from "../services/rest";


function Main() {
    const navigate = useNavigate();

    const [isHome, setIsHome] = React.useState<boolean>(true);
    const [label, setLabel] = React.useState<string>("Research Management");
    const [colabel, setColabel] = React.useState<string>("");

    useEffect(() => {
        const check_auth = async () => {
            const basic = sessionStorage.getItem('basic')
            console.log({basic})
            if (!basic)
                navigate('/CatalogManagement/login')
            else {
                const hc_res = await hc()
                if (!hc_res.Success) {
                    sessionStorage.removeItem('basic')
                    navigate(`/CatalogManagement/login`)
                }
            }
        }
        check_auth()
    })

    const hc = async () => new Promise<{ Success: boolean }>((resolve, reject) => {
        rest.hc().then(res => {
            let data: { Success: boolean } = (res?.data ?? res)
            resolve(data)
        }).catch(err => reject)
    })

    return (
        <div className={'assuta-top-container'} id={'assuta-frame-workspace-container'}>
            <Navbar isHome={isHome} navigate={navigate}/>

            <div id={"assuta-frame-workspace"}>
                <div className={"assuta-frame-workspace-header"}>
                    <div id={"assuta-frame-workspace-header-label"}>
                        {label}
                    </div>
                    <div id={"assuta-frame-workspace-header-time-label"}>
                        {moment().format("DD MMM YYYY")}
                    </div>
                    <div id={"assuta-frame-workspace-header-colabel"}>
                        {colabel}
                    </div>
                </div>
                <div id={"assuta-frame-workspace-body"}>
                    <Outlet />
                    <Routes>
                        <Route path="/" element={<Welcome setIsHome={setIsHome} setLabel={setLabel} setColabel={setColabel}/>}/>
                        <Route path="/researcher/*" element={<Researcher setIsHome={setIsHome} setLabel={setLabel} setColabel={setColabel}/>}/>
                        <Route path="/manage/*" element={<Management setIsHome={setIsHome} setLabel={setLabel} setColabel={setColabel}/>}/>
                    </Routes>
                </div>
            </div>
        </div>
    );
}

export default Main;

