import React, {useEffect} from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from './views/NotFound';
import './App.css';
import { useNavigate, useLocation  } from 'react-router-dom';
import CatalogManagement from "./CatalogManagement";

function App() {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (location.pathname === '/')
            navigate(`/CatalogManagement`)
    }, []);

    return (
        <Routes>
            <Route path="/CatalogManagement/*" element={<CatalogManagement />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default App;
