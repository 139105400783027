import React, {MouseEventHandler, useEffect, useState} from 'react';
import {MainBodyProps} from '../utils/types';
import {useNavigate} from "react-router-dom";



function Welcome({setLabel, setColabel, setIsHome}: MainBodyProps ) {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (isLoading) {
            setColabel("")
            setLabel("Research Management")
            setLoading(false)
            setIsHome(true)
        }
    }, [isLoading]);

    const navigateToEnvManager = (event: React.MouseEvent) => {
        navigate(`/CatalogManagement/main/manage`)
    }

    const navigateToNsManager = (event: React.MouseEvent) => {
        navigate(`/CatalogManagement/main/researcher`)
    }

    return (
        <div className={"assuta-frame-workspace-body-container welcome-body-container"}>
            <h3>Welcome the assuta research management framework</h3>
            <h4>Please choose:</h4>
            <div id={"welcome-page-actions"}>
                <button id={"welcome-page-manage-env-btn"} onClick={navigateToEnvManager} className={'btn welcome-page-btn'}>Manage Environments</button>
                <button id={"welcome-page-manage-ns-btn"} onClick={navigateToNsManager} className={'btn welcome-page-btn'}>Manage Namespace Data</button>
            </div>
        </div>

    );
}

export default Welcome;

