import Checkbox from '@mui/material/Checkbox';
import * as React from "react";
import {ChangeEvent, useEffect} from "react";
import {CheckboxProps} from "../../utils/types";
import FormControl from "@mui/material/FormControl";
import {FormControlLabel} from "@mui/material";


export default function CheckboxInput({
                                 label, name = "",
                                 value, onChange,
                                 id = "", className = "", rest
                             }: CheckboxProps) {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({name: event.target.name, value: event.target.checked})
    }

    return (
        <FormControl sx={{m: 1, width: rest?.width || 400}}>
            <FormControlLabel componentsProps={{ typography: { fontSize: '1.2rem', lineHeight: 1 } }} id={id} className={`assuta-checkbox-label ${className}-checkbox-label`} control={<Checkbox onChange={handleChange} className={`assuta-checkbox ${className}-checkbox`} name={name} checked={value} />} label={label} />
        </FormControl>
    )

}
