import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ComboBox from "../../components/inputs/ComboBox";
import TextArea from "../../components/inputs/TextArea";
import Text from "../../components/inputs/Text";
import {useNavigate} from "react-router-dom";
import {_Namespace, Namespace} from "../../utils/types";
import rest from "../../services/rest";

interface NamespaceColProps {
    namespaces: Namespace[];
    Namespace: _Namespace;
    setNamespace: (namespace: _Namespace) => void;
    SetEnvironmentCallback: () => void;
}
const NamespaceCol = ({namespaces, Namespace, setNamespace, SetEnvironmentCallback}: NamespaceColProps) => {
    const [isNew, setIsNew] = React.useState<boolean>(true);

    return (
        <Grid className={'Information-Content managment-frame'} container spacing={2} direction="row" justifyContent="space-evenly"
              alignItems="flex-start">
            <Grid className={'Information-Content-Item'} item xs={12} sm={6} md={4} lg={3}>
                <Paper className={'Information-Content-Item-Paper'} elevation={3} style={{
                    height: 'max-content',
                    width: '560px',
                    boxShadow: 'unset',
                    backgroundColor: 'transparent'
                }}>
                    <ComboBox label={'Namespace'} options={
                        namespaces.map(namespace => {return {label: namespace.Namespace, value: namespace.Namespace}}).concat(
                            [{label: "Add new", value: "new"}]
                        )
                    } onChange={value => {
                        if (value === 'new') {
                            setNamespace({})
                            setIsNew(true)
                        }else {
                            const {Namespace, Description} = (namespaces.find(namespace_obj => namespace_obj.Namespace === value) || {})
                            setNamespace({Namespace, Description})
                            setIsNew(false)
                        }
                    }} onSearchChange={console.log} selected={(namespaces.find(namespace_obj => namespace_obj.Namespace === Namespace.Namespace))?.Namespace || "new"}/>
                    <TextArea label={'Description'} name={'Description'} value={Namespace.Description || null} onChange={
                        event => setNamespace({Description: String(event.value)})
                    }/>
                </Paper>
            </Grid>
            <Grid className={'Information-Content-Item'} item xs={12} sm={6} md={4} lg={3}>
                <Paper className={'Information-Content-Item-Paper'} elevation={3} style={{
                    height: 'max-content',
                    width: '560px',
                    boxShadow: 'unset',
                    backgroundColor: 'transparent'
                }}>
                    <Text name={'Namespace'} disabled={!isNew} label={'Namespace'} value={Namespace.Namespace || ""} onChange={
                        event => setNamespace({ Namespace: String(event.value)})
                    }/>
                    <button className={'btn'} disabled={!Namespace.Namespace} style={{margin: '8px'}}
                            onClick={event => {
                                SetEnvironmentCallback()
                                setIsNew(false)
                            }}>Save</button>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default NamespaceCol;