import React from 'react';

function Logout() {
    return (
        <svg width={'100%'} height={'100%'} fill={"none"} id="עיגול_ירוק" data-name="עיגול ירוק" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 22 24">
            <path id="Vector" d="M10 12L19 12M19 12L17 10M19 12L17 14M15 16L15 19C15 20.1046 14.1046 21 13 21L7 21C5.89543 21 5 20.1046 5 19L5 5C5 3.89543 5.89543 3 7 3L13 3C14.1046 3 15 3.89543 15 5L15 8" stroke="#0f2c6c" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    );
}

export default Logout;
