import React, {useEffect, useState} from 'react';
import {IdentifiableInformationProps, PiiValues} from '../../utils/types'
import {PatientGenders} from '../../utils/constants'
import MultipleSelect from '../../components/inputs/MultipleSelect';
import rest from '../../services/rest'
import TransferList from "../../components/inputs/TransferList";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Text from "../../components/inputs/Text";
import {Divider} from "@mui/material";


const IdentifiableInformation: React.FC<IdentifiableInformationProps> = ({
                                                                             Namespace,
                                                                             MaxRows,
                                                                             PatientAgeFrom,
                                                                             PatientAgeTo,
                                                                             PatientGender,
                                                                             PatientCities,
                                                                             PatientOrigin,
                                                                             FatherOrigin,
                                                                             MotherOrigin,
                                                                             setParams,
                                                                             setColabel,
                                                                             setBtn,
                                                                             setBackBtn
                                                                         }) => {
    const [isLoading, setLoading] = useState(true);
    const [values, setValues] = React.useState<PiiValues>({});
    const [PatientOriginFilter, setPatientOriginFilter] =
        React.useState<{ search: string, final: boolean }>({search: "", final: false});
    const [FatherOriginFilter, setFatherOriginFilter] =
        React.useState<{ search: string, final: boolean }>({search: "", final: false});
    const [MotherOriginFilter, setMotherOriginFilter] =
        React.useState<{ search: string, final: boolean }>({search: "", final: false});
    const [PatientCitiesFilter, setPatientCitiesFilter] =
        React.useState<{ search: string, final: boolean }>({search: "", final: false});

    useEffect(() => {
        if (isLoading) {
            const fetchData = async () => {
                values['PatientCities'] = await getCities()
                const Countries = await getCountries()
                values['PatientOrigin'] = Countries
                values['FatherOrigin'] = Countries
                values['MotherOrigin'] = Countries

                setLoading(false);
            }
            setColabel('Identifiable Information Content')
            setBtn({
                text: 'Next',
                path: '/CatalogManagement/main/researcher/hi',
            })
            setBackBtn({
                text: 'Back',
                path: '/CatalogManagement/main/researcher',
                visible: true
            })
            fetchData()
        }
    }, [isLoading]);

    const setPatientOriginFilterSearch = async (search: string, final: boolean) => {
        try {
            let search_val = null
            if (search && search !== "")
                search_val = search
            const PatientOrigin = await getCountries(search_val, final)
            console.log({PatientOrigin})
            setValues(prevState => Object.assign(prevState,{PatientOrigin}))
        } catch (e: any) {
            console.log(e)
        }
        setPatientOriginFilter({search, final})
        console.log(search)
    }
    const setFatherOriginFilterSearch = async (search: string, final: boolean) => {
        try {
            let search_val = null
            if (search && search !== "")
                search_val = search
            const FatherOrigin = await getCountries(search_val, final)
            console.log({FatherOrigin})
            setValues(prevState => Object.assign(prevState,{FatherOrigin}))
        } catch (e: any) {
            console.log(e)
        }
        setFatherOriginFilter({search, final})
        console.log(search)
    }
    const setMotherOriginFilterSearch = async (search: string, final: boolean) => {
        try {
            let search_val = null
            if (search && search !== "")
                search_val = search
            const MotherOrigin = await getCountries(search_val, final)
            console.log({MotherOrigin})
            setValues(prevState => Object.assign(prevState,{MotherOrigin}))
        } catch (e: any) {
            console.log(e)
        }
        setMotherOriginFilter({search, final})
        console.log(search)
    }
    const setPatientCitiesFilterSearch = async (search: string, final: boolean) => {
        try {
            let search_val = null
            if (search && search !== "")
                search_val = search
            const PatientCities = await getCities(search_val, final)
            console.log({PatientCities})
            setValues(prevState => Object.assign(prevState,{PatientCities}))
        } catch (e: any) {
            console.log(e)
        }
        setPatientCitiesFilter({search, final})
        console.log(search)
    }

    const getCities = async (search?: string | null, final?: boolean) => new Promise<{ label: string, value: string }[]>((resolve, reject) => {
        rest.getCities(search, final).then(res => {
            let data: { Name: string, Code: string }[] = (res?.data ?? res).Cities
            const cities = data.map(city => {
                return {label: city.Name, value: city.Code}
            })
            resolve(cities)
        }).catch(err => reject)
    })
    const getCountries = async (search?: string | null, final?: boolean) => new Promise<{ label: string, value: string }[]>((resolve, reject) => {
        rest.getCountries(search, final).then(res => {
            let data: { Name: string, Code: string }[] = (res?.data ?? res).Countries
            const countries = data.map(country => {
                return {label: country.Name, value: country.Code}
            })
            resolve(countries)
        }).catch(err => reject)
    })

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (Object.keys(values).length === 0) {
        return <div>Error: Data not available</div>;
    }

    return (
        <Grid className={'Information-Content'} container spacing={2} direction="row" justifyContent="space-evenly"
              alignItems="flex-start">
            <div className={"namespace-display"}>{Namespace}</div>
            <Grid className={'Information-Content-Item'} item xs={12} sm={6} md={4} lg={3}>
                <Paper className={'Information-Content-Item-Paper'} elevation={3} style={{
                    height: 'max-content',
                    width: '560px',
                    boxShadow: 'unset',
                    backgroundColor: 'transparent'
                }}>
                    <Text type={'number'} className={"InformationInput"} name={'MaxRows'} label={'Number of Patients'}
                          value={MaxRows || null}
                          onChange={event => setParams(event.name, event.value)}
                          rest={{width: '98.5%'}}
                    />
                    <MultipleSelect
                        label={"Genders"}
                        onChange={val => setParams("PatientGender", val)}
                        options={PatientGenders}
                        selected={PatientGender || []}
                        className={"InformationInput"}
                        rest={{width: '98.5%'}}
                    />
                </Paper>
            </Grid>
            <Grid className={'Information-Content-Item'} item xs={12} sm={6} md={4} lg={3}>
                <Paper className={'Information-Content-Item-Paper'} elevation={3} style={{
                    height: 'max-content',
                    width: '560px',
                    boxShadow: 'unset',
                    backgroundColor: 'transparent'
                }}>
                    <Text type={'number'} className={"InformationInput"} name={'PatientAgeFrom'} label={'Age from'}
                          value={PatientAgeFrom || null}
                          onChange={event => setParams(event.name, event.value)}
                          rest={{width: '98.5%'}}
                    />
                    <Text type={'number'} className={"InformationInput"} name={'PatientAgeTo'} label={'Age to'}
                          value={PatientAgeTo || null}
                          onChange={event => setParams(event.name, event.value)}
                          rest={{width: '98.5%'}}
                    />
                </Paper>
            </Grid>
            <Grid className={'Information-Content-Item'} item xs={12} sm={6} md={4} lg={3}>
                <Paper className={'Information-Content-Item-Paper'} elevation={3} style={{
                    height: 'max-content',
                    width: '560px',
                    boxShadow: 'unset',
                    backgroundColor: 'transparent'
                }}>
                    <TransferList
                        label={"Birth Place"}
                        options={values?.PatientOrigin || []}
                        onChange={val => setParams("PatientOrigin", val)}
                        selected={PatientOrigin || []}
                        filterCallback={(search, final) => setPatientOriginFilterSearch(search, final)}
                        filterText={PatientOriginFilter.search}
                        final={PatientOriginFilter.final}
                        searchClassName={"InformationInput-TransferList-Search"}
                        className={"InformationInput InformationInput-TransferList"}/>
                </Paper>
            </Grid>
            <Grid className={'Information-Content-Item'} item xs={12} sm={6} md={4} lg={3}>
                <Paper className={'Information-Content-Item-Paper'} elevation={3} style={{
                    height: 'max-content',
                    width: '560px',
                    boxShadow: 'unset',
                    backgroundColor: 'transparent'
                }}>
                    <TransferList
                        label={"Father Birth Place"}
                        options={values?.FatherOrigin || []}
                        onChange={val => setParams("FatherOrigin", val)}
                        selected={FatherOrigin || []}
                        filterCallback={(search, final) => setFatherOriginFilterSearch(search, final)}
                        filterText={FatherOriginFilter.search}
                        final={FatherOriginFilter.final}
                        searchClassName={"InformationInput-TransferList-Search"}
                        className={"InformationInput InformationInput-TransferList"}/>
                </Paper>
            </Grid>
            <Grid className={'Information-Content-Item'} item xs={12} sm={6} md={4} lg={3}>
                <Paper className={'Information-Content-Item-Paper'} elevation={3} style={{
                    height: 'max-content',
                    width: '560px',
                    boxShadow: 'unset',
                    backgroundColor: 'transparent'
                }}>
                    <TransferList
                        label={"Mother Birth Place"}
                        options={values?.MotherOrigin || []}
                        onChange={val => setParams("MotherOrigin", val)}
                        selected={MotherOrigin || []}
                        filterCallback={(search, final) => setMotherOriginFilterSearch(search, final)}
                        filterText={MotherOriginFilter.search}
                        final={MotherOriginFilter.final}
                        searchClassName={"InformationInput-TransferList-Search"}
                        className={"InformationInput InformationInput-TransferList"}/>
                </Paper>
            </Grid>
            <Grid className={'Information-Content-Item'} item xs={12} sm={6} md={4} lg={3}>
                <Paper className={'Information-Content-Item-Paper'} elevation={3} style={{
                    height: 'max-content',
                    width: '560px',
                    boxShadow: 'unset',
                    backgroundColor: 'transparent'
                }}>
                    <TransferList
                        label={"Residence"}
                        options={values?.PatientCities || []}
                        onChange={val => setParams("PatientCities", val)}
                        selected={PatientCities || []}
                        filterCallback={(search, final) => setPatientCitiesFilterSearch(search, final)}
                        filterText={PatientCitiesFilter.search}
                        final={PatientCitiesFilter.final}
                        searchClassName={"InformationInput-TransferList-Search"}
                        className={"InformationInput InformationInput-TransferList"}/>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default IdentifiableInformation;
