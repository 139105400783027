import restApi from "./RestApi";
import queryString from 'query-string';
import {_Namespace, _User, Searchable} from "../../utils/types";


function transformSearchableOptions(searchable: Searchable): any {
    const transformed: any = { ...searchable };

    // Explicitly transform each Option[] key to string[]
    if (searchable.PatientGender) {
        transformed.PatientGender = searchable.PatientGender.map(option => option.value);
    }
    if (searchable.PatientEncounterTypes) {
        transformed.PatientEncounterTypes = searchable.PatientEncounterTypes.map(option => option.value);
    }
    if (searchable.PatientEncounterUnits) {
        transformed.PatientEncounterUnits = searchable.PatientEncounterUnits.map(option => option.value);
    }
    if (searchable.PatientCities) {
        transformed.PatientCities = searchable.PatientCities.map(option => option.value);
    }
    if (searchable.PatientOrigin) {
        transformed.PatientOrigin = searchable.PatientOrigin.map(option => option.value);
    }
    if (searchable.FatherOrigin) {
        transformed.FatherOrigin = searchable.FatherOrigin.map(option => option.value);
    }
    if (searchable.MotherOrigin) {
        transformed.MotherOrigin = searchable.MotherOrigin.map(option => option.value);
    }
    if (searchable.PatientDiagnoses) {
        transformed.PatientDiagnoses = searchable.PatientDiagnoses.map(option => option.value);
    }
    if (searchable.PatientLabResults) {
        transformed.PatientLabResults = searchable.PatientLabResults.map(option => option.value);
    }
    if (searchable.PatientVitalSigns) {
        transformed.PatientVitalSigns = searchable.PatientVitalSigns.map(option => option.value);
    }
    // No need to transform boolean flags or other non-Option[] types

    return transformed;
}

const hc = () => {
    return restApi.get('/hc', {
        maxBodyLength: Infinity,
        headers: {
            'Content-Type': 'application/json',
        }
    })
}
const getCities = (search?: string | null, final?: boolean) => {
    let path = '/Cities'
    const query: {SearchString?: string, Final?: boolean} = {}
    if (search)
        query['SearchString'] = search
    if (final)
        query['Final'] = final
    if (Object.keys(query).length)
        path += `?${queryString.stringify(query)}`
    return restApi.get(path, {
        maxBodyLength: Infinity,
        headers: {
            'Content-Type': 'application/json',
        }
    })
}
const getCountries = (search?: string | null, final?: boolean) => {
    let path = '/Origin'
    const query: {SearchString?: string, Final?: boolean} = {}
    if (search)
        query['SearchString'] = search
    if (final)
        query['Final'] = final
    if (Object.keys(query).length)
        path += `?${queryString.stringify(query)}`
    return restApi.get(path, {
        maxBodyLength: Infinity,
        headers: {
            'Content-Type': 'application/json',
        }
    })
}
const getDiagnoses = (search?: string | null, final?: boolean) => {
    let path = '/Diagnoses'
    const query: {SearchString?: string, Final?: boolean} = {}
    if (search)
        query['SearchString'] = search
    if (final)
        query['Final'] = final
    if (Object.keys(query).length)
        path += `?${queryString.stringify(query)}`
    return restApi.get(path, {
        maxBodyLength: Infinity,
        headers: {
            'Content-Type': 'application/json',
        }
    })
}
const getVitalSigns = (search?: string | null, final?: boolean) => {
    let path = '/VitalSings'
    const query: {SearchString?: string, Final?: boolean} = {}
    if (search)
        query['SearchString'] = search
    if (final)
        query['Final'] = final
    if (Object.keys(query).length)
        path += `?${queryString.stringify(query)}`
    return restApi.get(path, {
        maxBodyLength: Infinity,
        headers: {
            'Content-Type': 'application/json',
        }
    })
}
const getUnits = (search?: string | null, final?: boolean) => {
    let path = '/Units'
    const query: {SearchString?: string, Final?: boolean} = {}
    if (search)
        query['SearchString'] = search
    if (final)
        query['Final'] = final
    if (Object.keys(query).length)
        path += `?${queryString.stringify(query)}`
    return restApi.get(path, {
        maxBodyLength: Infinity,
        headers: {
            'Content-Type': 'application/json',
        }
    })
}
const getLabResults = (search?: string | null, final?: boolean) => {
    let path = '/LabResults'
    const query: {SearchString?: string, Final?: boolean} = {}
    if (search)
        query['SearchString'] = search
    if (final)
        query['Final'] = final
    if (Object.keys(query).length)
        path += `?${queryString.stringify(query)}`

    return restApi.get(path, {
        maxBodyLength: Infinity,
        headers: {
            'Content-Type': 'application/json',
        }
    })
}
const getNamespaces = () => {
    return restApi.get('/Environment/GetList', {
        maxBodyLength: Infinity,
        headers: {
            'Content-Type': 'application/json',
        }
    })
}
const postBuildRequest = (data: Searchable) => {
    return restApi.post('/BuildRequest', transformSearchableOptions(data),{
        headers: {
            'Content-Type': 'application/json',
        }
    })
}
const LoadPatientsData = (Namespace: string, Patients: string[]) => {
    return restApi.post('/LoadPatientsData', {Namespace, Patients},{
        headers: {
            'Content-Type': 'application/json',
        }
    })
}
const SetEnvironment = (data: _Namespace) => {
    return restApi.post('/Environment/SetEnv', data,{
        headers: {
            'Content-Type': 'application/json',
        }
    })
}
const SetUser = (Namespace: string, data: _User) => {
    return restApi.post('/Environment/SetUser', {
        Namespace,
        ...data
    },{
        headers: {
            'Content-Type': 'application/json',
        }
    })
}
export default {
    hc,
    getCities,
    getCountries,
    getDiagnoses,
    getVitalSigns,
    getUnits,
    getLabResults,
    getNamespaces,
    postBuildRequest,
    LoadPatientsData,
    SetEnvironment,
    SetUser
}