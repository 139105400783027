import React, {MouseEventHandler, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import moment from 'moment';
import { Outlet, Routes, Route } from 'react-router-dom';
import Login from './views/Login'
import Main from './views/Main'
import NotFound from "./views/NotFound";

function CatalogManagement() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const isAuthenitcated = true
        if (!isAuthenitcated || location.pathname === '/CatalogManagement') navigate(`/CatalogManagement/login`)
    }, []);

    return (
        <Routes>
            <Route path="/login" element={<Login/>}/>
            <Route path="/main/*" element={<Main/>}/>
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default CatalogManagement;

