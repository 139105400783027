import React, {MouseEventHandler, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {_Namespace, Namespace, MainBodyProps, User, _User} from '../../utils/types';
import '../../style/management/index.css'
import rest from "../../services/rest";
import NamespaceCol from "./NamespaceCol";
import UserCol from "./UserCol";


function Management({setLabel, setColabel, setIsHome}: MainBodyProps ) {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [Namespace, setNamespace] = React.useState<_Namespace>({});
    const [namespaces, setNamespaces] = React.useState<Namespace[]>([]);
    const [user, setUser] = React.useState<_User>({});

    useEffect(() => {
        if (isLoading) {
            const fetchData = async () => {
                const namespace = await getNameSpaces()
                setNamespaces(namespace)
                setLoading(false);
            }
            setLabel("Manage Environment")
            setIsHome(false)
            fetchData()
        }
    }, [isLoading]);

    useEffect(() => {
        console.log({Namespace})
    },[Namespace])

    const addUserToNamespace = (namespaceName: string | undefined, newUser: User) => {
        if (!namespaceName)
            return
        setNamespaces(prevNamespaces => {
            const updatedNamespaces = prevNamespaces.map(ns => {
                if (ns.Namespace === namespaceName) {
                    // Found the target namespace, now add the user
                    return {
                        ...ns,
                        Users: [...(ns.Users || []), newUser], // Add newUser to the Users array
                    };
                }
                return ns; // Return unchanged namespace
            });
            return updatedNamespaces;
        });
    }

    const getNameSpaces = () => new Promise<Namespace[]>((resolve, reject) => {
        rest.getNamespaces().then(res => {
            let data: Namespace[] = (res?.data ?? res)
            resolve(data)
        }).catch(err => reject)
    })

    const setNamespaceAttributes = (attributes: _Namespace) => {
        if (Object.keys(attributes).length === 0)
            setNamespace({})
        else setNamespace( Object.assign(JSON.parse(JSON.stringify(Namespace)), attributes))
    }

    const setUserAttributes = (attributes: _User) => {
        if (Object.keys(attributes).length === 0)
            setUser({})
        else setUser( Object.assign(JSON.parse(JSON.stringify(user)), attributes))
    }

    const SetEnvironment = () => new Promise<void>((resolve, reject) => {
        rest.SetEnvironment(Namespace).then(res => {
            let data: Namespace = (res?.data ?? res)
            setNamespaces(prevState => prevState.concat(data))
            setNamespaceAttributes(Namespace)
            resolve()
        }).catch(err => reject)
    })

    const SetUser = () => new Promise<void>((resolve, reject) => {
        if (!Namespace.Namespace)
            reject("Missing Namespace")
        const user_data = ({...user, Password: '123456aA!'} as User)
        rest.SetUser(String(Namespace.Namespace), user_data).then(res => {
            let data: {Success: boolean} = (res?.data ?? res)
            addUserToNamespace(Namespace.Namespace, user_data)
            resolve()
        }).catch(err => reject)
    })

    return (
        <div className={"assuta-frame-workspace-body-container researcher-body-container"}>
            <div id={"assuta-frame-workspace-environment-management"}>
                <div id={"assuta-frame-workspace-namespace-management-col"} className={"assuta-frame-workspace-environment-management-col"}>
                    <NamespaceCol
                        namespaces={namespaces}
                        Namespace={Namespace}
                        setNamespace={setNamespaceAttributes}
                        SetEnvironmentCallback={SetEnvironment}
                    />
                </div>
                <div id={"assuta-frame-workspace-user-management-col"} className={"assuta-frame-workspace-environment-management-col"}>
                    <UserCol
                        Namespace={(namespaces.find(namespace_obj => namespace_obj.Namespace === Namespace.Namespace))?.Namespace}
                        Users={(namespaces.find(namespace_obj => namespace_obj.Namespace === Namespace.Namespace))?.Users || []}
                        user={user}
                        setUser={setUserAttributes}
                        SetUserCallback={SetUser}
                    />
                </div>
            </div>

        </div>

    );
}

export default Management;

