import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import assuta_en from "../assets/assuta_en.png";
import Text from "../components/inputs/Text";
import api from "../services/rest/api";
import rest from "../services/rest";

function Login() {
    const navigate = useNavigate();
    const [auth,setAuth] = useState<
    {
        user?: string;
        password?: string
    }>({})


    useEffect(() => {
        sessionStorage.removeItem('basic')
    })

    const setAuthParams = async (event: React.MouseEvent) => {
        if (!(auth.user && auth.password)){
            setAuth({})
            return
        } else {
            const authorization = 'Basic ' + btoa(auth.user + ":" + auth.password)
            await sessionStorage.setItem('basic', authorization)
           try {
               const hc_res = await hc()
               console.log({hc_res})
               if (!hc_res.Success) {
                   alert("Wrong username or password")
                   sessionStorage.removeItem('basic')
                   setAuth({})
               } else {
                   navigate(`/CatalogManagement/main`)
               }
           } catch (e) {
               alert("Wrong username or password")

           }
        }
    }

    const hc = async () => new Promise<{ Success: boolean }>((resolve, reject) => {
        rest.hc().then(res => {
            let data: { Success: boolean } = (res?.data ?? res)
            resolve(data)
        }).catch(err => reject)
    })

    return (
        <div style={{height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div className={'auth-action-container'}>
                <div className={"assuta-frame-workspace-header"}>
                    <img src={assuta_en} className={"assuta-frame-workspace-navbar-logo"} alt={'business_logo'}/>
                </div>
                <div className={"auth-action-name-container"}>
                    <label className={"auth-action-name"}>Login Account</label>
                    <label className={"auth-action-details"}>Please enter your details</label>
                </div>

                <div className={"auth-action-form-container"}>
                    <Text className={"auth-action-form-input"} name={'user'} label={'User Name'} value={auth?.user || null} onChange={
                        event => setAuth(auth => ({ ...auth, [event["name"]]: event["value"] }))
                    } rest={{
                        width: '90%'
                    }}/>
                    <Text className={"auth-action-form-input"} type={'password'} name={'password'} label={'Password'} value={auth?.password || null} onChange={
                        event => setAuth(auth => ({ ...auth, [event["name"]]: event["value"] }))
                    } rest={{
                        width: '90%'
                    }}/>
                    <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <button className={"btn auth-action-form-submit"} onClick={setAuthParams}>Login</button>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Login;