import React from 'react';
import assuta_en from '../../assets/assuta_en.png'
import '../../style/NavBar.css'
import SVG from "../../assets/SVG";
import {NavBarProps} from "../../utils/types";
import logout from "../../assets/SVG/Logout";
function Navbar({isHome, navigate}: NavBarProps) {
    const navigateToHome = (event: React.MouseEvent) => {
        navigate(`/CatalogManagement/main`)
    }

    const LogoutCallback = (event: React.MouseEvent) => {
        sessionStorage.removeItem('basic')
        navigate(`/CatalogManagement/login`)
    }

    return (
        <div id={"assuta-frame-workspace-navbar"}>
            <div className={"assuta-frame-workspace-navbar-header"}>
                <img src={assuta_en} id={"assuta-frame-workspace-navbar-logo"} alt={'business_logo'}/>
            </div>

            <div id={"assuta-frame-workspace-navbar-menu"}>

            </div>

            <div id={"assuta-frame-workspace-navbar-footer"}>
                <div id={"assuta-frame-workspace-navbar-footer-container"}>
                    <div onClick={LogoutCallback} id={"assuta-frame-workspace-navbar-logout"} className={"assuta-frame-workspace-navbar-footer-action"}>
                        <SVG name={'Logout'} className={"assuta-frame-workspace-navbar-footer-icon"} height={'16px'}/>
                        <h5 className={"assuta-frame-workspace-navbar-footer-label"}>Logout</h5>
                    </div>
                    {!isHome && <div onClick={navigateToHome} id={"assuta-frame-workspace-navbar-home-btn"} className={"assuta-frame-workspace-navbar-footer-action"}>
                        <SVG name={'Home'} className={"assuta-frame-workspace-navbar-footer-icon"} height={'16px'}/>
                        <h5 className={"assuta-frame-workspace-navbar-footer-label"}>Home</h5>
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default Navbar;

