import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Outlet, Routes, Route } from 'react-router-dom';
import HealthInformation from './HealthInformation';
import IdentifiableInformation from './IdentifiableInformation';
import Results from './Results';
import {MainBodyProps, Option, Searchable} from '../../utils/types';
import '../../style/researcher/index.css'
import Home from "./Home";



function Researcher({setLabel, setColabel, setIsHome}: MainBodyProps ) {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const resultsRef = React.useRef<any>(null); // Replace 'any' with the appropriate type if possible

    const [btn, setBtn] = React.useState<{ text: string, path: string, visible: boolean, disabled: boolean }>({
        text: 'Next',
        path: '/CatalogManagement/main/researcher/ii',
        disabled: false,
        visible: true
    });
    const [backBtn, setBackBtn] = React.useState<{ text: string, path: string, visible: boolean }>({
        text: '',
        path: '',
        visible: false
    });
    const [searchParams, setSearchParams] = React.useState<Searchable>({});

    useEffect(() => {
        if (isLoading) {
            setLabel("Research")
            setLoading(false)
            setIsHome(false)
        }
        if (!searchParams.Namespace){
            navigate('/CatalogManagement/main/researcher')
        }
    }, [isLoading]);


    const setParams = (name: string, value : number | number[] | string | string[] | boolean | Option[]) => {
        setSearchParams(prevParams => ({
            ...prevParams,
            [name]: value
        }));
    }

    const setColabelCallback = (colabel: string) => {
        if (searchParams.Namespace)
            setColabel(`${searchParams.Namespace}: ${colabel}`)
        else  setColabel(colabel)
    }
    useEffect(() => {
        if (!searchParams.Namespace) {
            setBtn(prevState => Object.assign(prevState, {disabled: true}))
        }
    }, [searchParams]);

    return (
        <div className={"assuta-frame-workspace-body-container researcher-body-container"}>
            <Outlet />
            <Routes>
                <Route path="/" element={
                    <Home
                        Namespace={searchParams.Namespace}
                        setParams={setParams}
                        setColabel={setColabelCallback}
                        setBtn={state => setBtn(prevState => Object.assign(prevState, state))}
                        setBackBtn={state => setBackBtn(prevState => Object.assign(prevState, state))}
                    />
                } />
                <Route path="/ii" element={
                    <IdentifiableInformation
                        Namespace={searchParams.Namespace}
                        PatientAgeFrom={searchParams.PatientAgeFrom}
                        PatientAgeTo={searchParams.PatientAgeTo}
                        PatientGender={searchParams.PatientGender}
                        PatientCities={searchParams.PatientCities}
                        PatientOrigin={searchParams.PatientOrigin}
                        FatherOrigin={searchParams.FatherOrigin}
                        MotherOrigin={searchParams.MotherOrigin}
                        MaxRows={searchParams.MaxRows}
                        setParams={setParams}
                        setColabel={setColabelCallback}
                        setBtn={state => setBtn(prevState => Object.assign(prevState, state))}
                        setBackBtn={state => setBackBtn(prevState => Object.assign(prevState, state))}
                    />
                } />
                <Route path="/hi" element={
                    <HealthInformation
                        Namespace={searchParams.Namespace}
                        PatientEncounterTypes={searchParams.PatientEncounterTypes}
                        PatientEncounterUnits={searchParams.PatientEncounterUnits}
                        PatientDiagnoses={searchParams.PatientDiagnoses}
                        PatientLabResults={searchParams.PatientLabResults}
                        PatientLabResultsExists={searchParams.PatientLabResultsExists}
                        PatientVitalSigns={searchParams.PatientVitalSigns}
                        PatientVitalSignsExists={searchParams.PatientVitalSignsExists}
                        PatientMicroResultsExists={searchParams.PatientMicroResultsExists}
                        setParams={setParams}
                        setColabel={setColabelCallback}
                        setBtn={state => setBtn(prevState => Object.assign(prevState, state))}
                        setBackBtn={state => setBackBtn(prevState => Object.assign(prevState, state))}
                    />
                } />
                <Route path="/results" element={
                    <Results
                        Namespace={searchParams.Namespace}
                        ref={resultsRef}
                        clearParams={() => setSearchParams({})}
                        searchParams={searchParams}
                        setBackBtn={state => setBackBtn(prevState => Object.assign(prevState, state))}
                        setBtn={state => setBtn(prevState => Object.assign(prevState, state))}
                        setColabel={setColabelCallback}
                    />
                } />
            </Routes>
            <div id={"researcher-body-footer"}>
                <div id={"assuta-frame-workspace-footer-left"}>
                    <button disabled={Object.keys(searchParams).length === 0} className={'assuta-frame-workspace-footer-btn btn'} onClick={event => {
                        setSearchParams({})
                        setBtn(prevState => Object.assign(prevState, {disabled: true}))
                        navigate('/CatalogManagement/main/researcher')
                    }}>Clear Data</button>
                </div>
                <div id={"assuta-frame-workspace-footer-right"}>
                    <button className={'assuta-frame-workspace-footer-btn btn'} style={{display: backBtn.visible? 'unset' : 'none'}}
                            onClick={event => {
                                let path = backBtn.path
                                navigate(path)
                            }}>{backBtn.text}</button>

                    <button className={'assuta-frame-workspace-footer-btn btn'} disabled={btn.disabled} style={{display: btn.visible? 'unset' : 'none'}}
                            onClick={event => {
                                let path = btn.path
                                navigate(path)
                                if (btn.text === 'Done' && resultsRef.current) {
                                    resultsRef.current.loadPatientsData()
                                }
                            }}>{btn.text}</button>
                </div>
            </div>
        </div>

    );
}

export default Researcher;

