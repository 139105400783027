import React, {Component} from 'react'
import PropTypes from "prop-types";
import '../style/NotFound.css'

class NotFound extends Component {
    render() {

        return (
            <div id="message">
                <h2>404</h2>
                <h1>Page Not Found</h1>
                <p>The specified file was not found on this website. Please check the URL for mistakes and try
                    again.</p>
            </div>
        );
    }
}

NotFound.propTypes = {
    title: PropTypes.string
};

export default NotFound;
