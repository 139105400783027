import axios from "axios";
import {backend_url, protocol, base_path} from '../../config'

const request = axios.create({
    baseURL: `${protocol}://${backend_url}/${base_path}`,
})

request.interceptors.request.use(async (config) => {
    config.headers = Object.assign(config.headers, {
        'Content-Type': 'application/json',
    })
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

request.interceptors.response.use(
    async (response) => {
        return Promise.resolve(response?.data ?? response);
    },
    async (error) => {
        return Promise.resolve({error, data: {message: 'Connection Error Or Timed out'}});
    }
);

export default request