import React, {useEffect, useImperativeHandle, useState} from 'react';
import {ResultsProps, Searchable} from "../../utils/types";
import rest from "../../services/rest";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SVG from "../../assets/SVG";

interface PatientData {
    Patient: number;
    FirstName: string;
    LastName: string;
    Gender: string;
    IdNum: string;
    BirthDate: string;
}


interface TableRowProps {
    data: PatientData;
    onDelete: () => void;
}

const TableRow: React.FC<TableRowProps> = ({ data, onDelete }) => {
    return (
        <tr className="table-row">
            <td className="table-cell">{data.Patient}</td>
            <td className="table-cell">{data.FirstName}</td>
            <td className="table-cell">{data.LastName}</td>
            <td className="table-cell">{data.Gender}</td>
            <td className="table-cell">{data.IdNum}</td>
            <td className="table-cell">{data.BirthDate}</td>
            <td className="table-cell">
                <SVG className="delete-button" name={'Delete'} onClick={onDelete} height={'20px'}/>
            </td>
        </tr>
    );
};


const Results: React.ForwardRefExoticComponent<React.PropsWithoutRef<ResultsProps> & React.RefAttributes<unknown>> = React.forwardRef((
    {
        Namespace,
        searchParams, clearParams, setColabel, setBtn, setBackBtn
    }: ResultsProps, ref) => {

    const [isLoading, setLoading] = useState(true);
    const [patients, setPatients] = React.useState<PatientData[]>([]);

    useEffect(() => {
        if (isLoading) {
            const fetchData = async () => {

                await setPatients(await postBuildRequest(searchParams))
                setLoading(false);
            }
            setColabel('Query Results')
            setBtn({
                path: '/CatalogManagement/main/researcher',
                text: 'Done'
            })
            setBackBtn({
                text: 'Back',
                path: '/CatalogManagement/main/researcher/hi',
                visible: true
            })
            fetchData()
        }
    }, [isLoading]);


    const postBuildRequest = (searchParams: Searchable) => new Promise<PatientData[]>((resolve, reject) => {
        rest.postBuildRequest(searchParams).then(res => {
            let data: PatientData[] = (res?.data ?? res).Patients
            resolve(data ?? [])
        }).catch(reject)
    })

    const removePatient = (patientToRemove: number) => {
        const updatedPatients = patients.filter((patient) => patient.Patient !== patientToRemove);
        setPatients(updatedPatients);
    };

    const loadPatientsData = () => new Promise<void>((resolve, reject) => {
        const namespace = searchParams.Namespace || ""
        console.log({namespace})
        rest.LoadPatientsData(namespace, patients.map(obj => obj.Patient.toString())).then(res => {
            clearParams()
            resolve()
        }).catch((err: any) => reject)

    })

    useImperativeHandle(ref, () => ({
        loadPatientsData: loadPatientsData
    }));

    if (isLoading) {
        return <div>Loading...</div>;
    }

    // if (patients.length === 0) {
    //     return <div>No patients were selected</div>;
    // }

    return (
        <Grid className={'Information-Content results-page'} container spacing={2} direction="row" justifyContent="space-evenly"
              alignItems="flex-start">
            <div className={"namespace-display"}>{Namespace}</div>
            <Typography sx={{mt: 4, mb: 2}} variant="h6" component="div">
                Results
            </Typography>
            <table className="custom-table">
                <thead>
                <tr>
                    <th className="table-header">Patient</th>
                    <th className="table-header">First Name</th>
                    <th className="table-header">Last Name</th>
                    <th className="table-header">Gender</th>
                    <th className="table-header">ID Number</th>
                    <th className="table-header">Birth Date</th>
                    <th className="table-header">Action</th>
                </tr>
                </thead>
                <tbody>
                {patients.map((rowData, index) => (
                    <TableRow key={index} data={rowData} onDelete={() => removePatient(rowData.Patient)} />
                ))}
                {
                    patients.length === 0 &&  <div style={{padding: '30px 0' }}>No patients were found. Try to change the search conditions.</div>
                }
                </tbody>
            </table>
        </Grid>
    );
});

export default Results;