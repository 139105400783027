import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {MultipleSelectProps, Option} from "../../utils/types";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import {Box, Chip} from "@mui/material";

const isSelected = (value: string, selected: Option[]): number => {
    return selected.findIndex(sItem => sItem.value === value);
}

function getStyles(value: string, selected: Option[], theme: Theme) {
    let index: number = isSelected(value, selected);
    return {
        fontWeight: theme.typography[index === -1 ? 'fontWeightRegular' : 'fontWeightBold']
    }
}

function MultipleSelect({
        label, options,
        selected, onChange,
        id = "", className = "", rest
    }: MultipleSelectProps) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();

    const handleChange = (
        event: React.ChangeEvent<{}>,
        selectedOptions: { label: string, value: string; }[]
    ) => {
        onChange(selectedOptions)
    };

    return (
            <FormControl sx={{m: 1, width: rest?.width || 400}}>
                <Autocomplete
                    sx={{width: rest?.width || 400}}
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    multiple
                    id={id}
                    value={options.filter(option => selected.some(sItem => sItem.value === option.value))}
                    options={options}
                    className={`assuta-input ${className}`}
                    onChange={handleChange}
                    getOptionLabel={(option) => option.label}
                    // defaultValue={[top100Films[13]]}
                    filterSelectedOptions
                    disableCloseOnSelect
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                        />
                    )}
                    renderTags={(selectedOptions: readonly { label: string; value: string; }[], getTagProps) => {
                        const values = selectedOptions.slice(0,2).map((option: { label: string; value: string; }, index: number) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                <Chip label={option.label} {...getTagProps({ index })}/>
                            </Box>
                        ))

                        if (selectedOptions.length > 2) {
                            values.push(<Chip style={{fontSize: 'small', height: '45%'}} label={`+${selectedOptions.length -2} more`}/>)
                        }

                        return values;
                    }}
                    renderOption={(props, option, state, ownerState) => (
                        <MenuItem {...props} key={option.label} value={option.value} style={getStyles(String(option.value), selected, theme)}>
                            <Checkbox checked={isSelected(option.value, selected) > -1} />
                            <ListItemText primary={ownerState.getOptionLabel(option)} />
                        </MenuItem>
                    )}
                />
            </FormControl>
    );
}

export default MultipleSelect;