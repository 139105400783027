import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from "@mui/material/MenuItem";
import {Theme, useTheme} from "@mui/material/styles";
import {useEffect} from "react";
import FormControl from '@mui/material/FormControl';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function getStyles(name: string, selected: string | undefined, theme: Theme) {
    return {
        fontWeight:
            !!name && name === selected
                ? theme.typography.fontWeightBold
                : theme.typography.fontWeightRegular,
    };
}

interface dataProps {
    label: string;
    id?: string;
    className?: string;
    options: { label: string; value: string }[];
    onChange: (event: string | undefined) => void;
    onSearchChange: (event: string | null) => void;
    selected: string | undefined;
}

export default function ComboBox({options, selected, onChange, onSearchChange, label, id, className}:dataProps) {
    const [inputValue, setInputValue] = React.useState('');
    const theme = useTheme();

    useEffect(() => {
        onSearchChange(inputValue)
    }, [inputValue]);

    return (
        <div>
            <FormControl sx={{ m: 1, width: '400px' }}>
                <Autocomplete
                disablePortal
                id={id}
                className={`assuta-input ${className}`}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);

                }}

                value={options.find(option => option.value === selected) || null}
                onChange={(event: any, newValue: { label: string; value: string } | null) => {
                    onChange(newValue ? newValue.value : undefined);
                }}
                options={options}
                sx={{
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: '400px',
                }}
                renderOption={(props, option, state, ownerState) => (
                    <MenuItem
                        key={option.label}
                        value={option.value}
                        style={getStyles(option.value, selected, theme)}
                        {...props}
                    >
                        {ownerState.getOptionLabel(option)}
                    </MenuItem>
                )}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => <TextField {...params} label={label} />}
            />
            </FormControl>
        </div>
    );
}