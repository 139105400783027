import axios from "./request";

class RestApi {
    async get(url: string, config: {headers: {'Content-Type'?: string, 'Authorization'?: string}, maxBodyLength: number}) {
        config.headers.Authorization = (sessionStorage.getItem('basic') || "Unauthorized")
        return (await axios.get(url, config))
    }

    async post(url: string, data: any, config: {headers: {'Content-Type'?: string, 'Authorization'?: string}}) {
        config.headers.Authorization = (sessionStorage.getItem('basic') || "Unauthorized")
        return (await axios.post(url, data, config))
    }

    async put(url: string, data: any, config: {headers: {'Content-Type'?: string, 'Authorization'?: string}}) {
        config.headers.Authorization = (sessionStorage.getItem('basic') || "Unauthorized")
        return (await axios.put(url, data, config))
    }

    async delete(url: string, config: {headers: {'Content-Type'?: string, 'Authorization'?: string}}) {
        config.headers.Authorization = (sessionStorage.getItem('basic') || "Unauthorized")
        return (await axios.delete(url, config))
    }

}

let restApi = new RestApi()
export default restApi;
