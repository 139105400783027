import React from 'react';
import AssutaLogo from './AssutaLogo';
import Logout from './Logout';
import Home from "./Home";
import Delete from "./Delete";
interface dataProps {
    name: string;
    onClick?: (event: React.MouseEvent) => void;
    width?: string;
    height?: string;
    className?: string;
}

function SVG({
    name, onClick = (event: React.MouseEvent) => {}, width = "100%", height = "100%", className = "AssutaSVGClass"
}: dataProps) {

    const vectorGraphics = (name: string): React.ReactNode  => {
        switch (name) {
            case "AssutaLogo":
                return AssutaLogo();
            case "Logout":
                return Logout();
            case "Home":
                return Home();
            case "Delete":
                return Delete();
        }
    }

    return (
        <div className={"AssutaSVG " + className} style={{
            height, width
        }} onClick={onClick}>
            {vectorGraphics(name)}
        </div>
    );
}

export default SVG;
