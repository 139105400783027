import React, {useEffect, useState} from 'react';
import {HealthInformationProps, PhiValues} from '../../utils/types'
import rest from "../../services/rest";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MultipleSelect from "../../components/inputs/MultipleSelect";
import {PatientEncounters} from "../../utils/constants";
import TransferList from "../../components/inputs/TransferList";
import CheckboxInput from "../../components/inputs/CheckboxInput";

const HealthInformation: React.FC<HealthInformationProps> = ({
         Namespace,
         PatientEncounterTypes, PatientEncounterUnits,
         PatientDiagnoses, PatientLabResults,
         PatientLabResultsExists, PatientVitalSigns,
         PatientVitalSignsExists, PatientMicroResultsExists,
         setParams, setColabel, setBtn, setBackBtn
     }: HealthInformationProps) => {

    const [isLoading, setLoading] = useState(true);
    const [values, setValues] = React.useState<PhiValues>({});
    const [PatientEncountersFilter, setPatientEncountersFilter] =
        React.useState<{ search: string, final: boolean }>({search: "", final: false});
    const [UnitsFilter, setUnitsFilter] =
        React.useState<{ search: string, final: boolean }>({search: "", final: false});
    const [LabResultsFilter, setLabResultsFilter] =
        React.useState<{ search: string, final: boolean }>({search: "", final: false});
    const [VitalSignsFilter, setVitalSignsFilter] =
        React.useState<{ search: string, final: boolean }>({search: "", final: false});
    const [DiagnosesFilter, setDiagnosesFilter] =
        React.useState<{ search: string, final: boolean }>({search: "", final: false});
    const [filters, setFilters] = useState({
        UnitsFilter: "",
        LabResultsFilter: "",
        VitalSignsFilter: "",
        DiagnosesFilter: ""
    });

    const setUnitsFilterSearch = async (search: string, final: boolean) => {
        try {
            let search_val = null
            if (search && search !== "")
                search_val = search
            const Units = await getUnits(search_val, final)
            console.log({Units})
            setValues(prevState => Object.assign(prevState,{Units}))
        } catch (e: any) {
            console.log(e)
        }
        setUnitsFilter({search, final})
        console.log(search)
    }

    const setLabResultsFilterSearch = async (search: string, final: boolean) => {
        try {
            let search_val = null
            if (search && search !== "")
                search_val = search
            const LabResults = await getLabResults(search_val, final)
            console.log({LabResults})
            setValues(prevState => Object.assign(prevState,{LabResults}))
        } catch (e: any) {
            console.log(e)
        }
        setLabResultsFilter({search, final})
        console.log(search)
    }
    const setVitalSignsFilterSearch = async (search: string, final: boolean) => {
        try {
            let search_val = null
            if (search && search !== "")
                search_val = search
            const VitalSigns = await getVitalSigns(search_val, final)
            console.log({VitalSigns})
            setValues(prevState => Object.assign(prevState,{VitalSigns}))
        } catch (e: any) {
            console.log(e)
        }
        setVitalSignsFilter({search, final})
        console.log(search)
    }
    const setDiagnosesFilterSearch = async (search: string, final: boolean) => {
        try {
            let search_val = null
            if (search && search !== "")
                search_val = search
            const Diagnoses = await getDiagnoses(search_val, final)
            console.log({Diagnoses})
            setValues(prevState => Object.assign(prevState,{Diagnoses}))
        } catch (e: any) {
            console.log(e)
        }
        setDiagnosesFilter({search, final})
        console.log(search)
    }


    useEffect(() => {
        if (isLoading) {
            const fetchData = async () => {
                try {
                    const Units = await getUnits()
                    setValues(prevState => Object.assign(prevState,{Units}))
                    const LabResults = await getLabResults()
                    setValues(prevState => Object.assign(prevState,{LabResults}))
                    const VitalSigns = await getVitalSigns()
                    setValues(prevState => Object.assign(prevState,{VitalSigns}))
                    const Diagnoses = await getDiagnoses()
                    setValues(prevState => Object.assign(prevState,{Diagnoses}))
                } catch (e: any) {
                    console.log(e)
                }
                setLoading(false);
            }
            setColabel('Health Information Content')
            setBtn({
                text: 'Next',
                path: '/CatalogManagement/main/researcher/results',
                visible: true
            })
            setBackBtn({
                text: 'Back',
                path: '/CatalogManagement/main/researcher/ii',
                visible: true
            })
            fetchData()
        }
    }, [isLoading]);



    const getDiagnoses = (search?: string | null, final?: boolean) => new Promise<{label: string, value: string}[]>((resolve, reject) => {
        rest.getDiagnoses(search, final).then(res => {
            let data: {Name: string, Code: string}[] = (res?.data ?? res).Diagnoses
            const diagnoses = data.map(diagnose => {return {label: diagnose.Name, value: diagnose.Code}})
            resolve(diagnoses)
        }).catch((err: any) => reject)
    })

    const getVitalSigns = (search?: string | null, final?: boolean) => new Promise<{label: string, value: string}[]>((resolve, reject) => {
        rest.getVitalSigns(search, final).then(res => {
            let data: {Name: string, Code: string}[] = (res?.data ?? res).VitalSigns
            const vitalSigns = data.map(vitalSign => {return {label: vitalSign.Name, value: vitalSign.Code}})
            resolve(vitalSigns)
        }).catch((err: any) => reject)
    })

    const getUnits = (search?: string | null, final?: boolean) => new Promise<{label: string, value: string}[]>((resolve, reject) => {
        rest.getUnits(search, final).then(res => {
            let data: {Name: string, Code: string}[] = (res?.data ?? res).Units
            const units = data.map(unit => {return {label: unit.Name, value: unit.Code}})
            resolve(units)
        }).catch((err: any) => reject)
    })

    const getLabResults = (search?: string | null, final?: boolean) => new Promise<{label: string, value: string}[]>((resolve, reject) => {
        rest.getLabResults(search, final).then(res => {
            let data: {Name: string, Code: string}[] = (res?.data ?? res).LabResults
            const labResults = data.map(labResult => {return {label: labResult.Name, value: labResult.Code}})
            resolve(labResults)
        }).catch((err: any) => reject)
    })

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (Object.keys(values).length === 0) {
        return <div>Error: Data not available</div>;
    }

    return (
        <Grid className={'Information-Content'} container spacing={2} direction="row" justifyContent="start" alignItems="flex-start">
            <div className={"namespace-display"}>{Namespace}</div>
            <Grid className={'Information-Content-Item'} item xs={12} sm={6} md={4} lg={3}>
                <Paper className={'Information-Content-Item-Paper'} elevation={3} style={{ height: 'max-content', width: '560px', boxShadow: 'unset', backgroundColor: 'transparent' }}>
                    <MultipleSelect
                        label={"Encounter Types"}
                        onChange={val => setParams("PatientEncounterTypes", val)}
                        options={PatientEncounters}
                        selected={PatientEncounterTypes || []}
                        className={"InformationInput"}
                        rest={{width: '98.5%'}}
                    />
                    <CheckboxInput rest={{width: '98.5%'}} name={'PatientLabResultsExists'} label={'Lab Results Exists'} value={!!PatientLabResultsExists} onChange={event => setParams(event.name, event.value)}/>
                    <CheckboxInput rest={{width: '98.5%'}} name={'PatientVitalSignsExists'} label={'Vital Signs Exists'} value={!!PatientVitalSignsExists} onChange={event => setParams(event.name, event.value)}/>
                    <CheckboxInput rest={{width: '98.5%'}} name={'PatientMicroResultsExists'} label={'Micro Results Exists'} value={!!PatientMicroResultsExists} onChange={event => setParams(event.name, event.value)}/>
                </Paper>
            </Grid>
            <Grid className={'Information-Content-Item'} item xs={12} sm={6} md={4} lg={3}>
                <Paper className={'Information-Content-Item-Paper'} elevation={3} style={{ height: 'max-content', width: '560px', boxShadow: 'unset', backgroundColor: 'transparent' }}>

                    <TransferList
                        label={"Encounter Units"}
                        options={values?.Units || []}
                        onChange={val => setParams("PatientEncounterUnits", val)}
                        selected={PatientEncounterUnits || []}
                        filterCallback={(search, final) => setUnitsFilterSearch(search, final)}
                        filterText={UnitsFilter.search}
                        final={UnitsFilter.final}
                        searchClassName={"InformationInput-TransferList-Search"}
                        className={"InformationInput InformationInput-TransferList"}/>
                </Paper>
            </Grid>
            <Grid className={'Information-Content-Item'} item xs={12} sm={6} md={4} lg={3}>
                <Paper className={'Information-Content-Item-Paper'} elevation={3} style={{ height: 'max-content', width: '560px', boxShadow: 'unset', backgroundColor: 'transparent' }}>
                    <TransferList
                        label={"Diagnoses"}
                        options={values?.Diagnoses || []}
                        onChange={val => setParams("PatientDiagnoses", val)}
                        selected={PatientDiagnoses || []}
                        filterCallback={(search, final) => setDiagnosesFilterSearch(search, final)}
                        filterText={DiagnosesFilter.search}
                        final={DiagnosesFilter.final}
                        searchClassName={"InformationInput-TransferList-Search"}
                        className={"InformationInput InformationInput-TransferList"}/>
                </Paper>
            </Grid>
            <Grid className={'Information-Content-Item'} item xs={12} sm={6} md={4} lg={3}>
                <Paper className={'Information-Content-Item-Paper'} elevation={3} style={{ height: 'max-content', width: '560px', boxShadow: 'unset', backgroundColor: 'transparent' }}>
                    <TransferList
                        label={"Lab Results"}
                        options={values?.LabResults || []}
                        onChange={val => setParams("PatientLabResults", val)}
                        selected={PatientLabResults || []}
                        filterCallback={(search, final) => setLabResultsFilterSearch(search, final)}
                        filterText={LabResultsFilter.search}
                        final={LabResultsFilter.final}
                        searchClassName={"InformationInput-TransferList-Search"}
                        className={"InformationInput InformationInput-TransferList"}/>
                </Paper>
            </Grid>
            <Grid className={'Information-Content-Item'} item xs={12} sm={6} md={4} lg={3}>
                <Paper className={'Information-Content-Item-Paper'} elevation={3} style={{ height: 'max-content', width: '560px', boxShadow: 'unset', backgroundColor: 'transparent' }}>
                    <TransferList
                        label={"Vital Sings"}
                        options={values?.VitalSigns || []}
                        onChange={val => setParams("PatientVitalSigns", val)}
                        selected={PatientVitalSigns || []}
                        filterCallback={(search, final) => setVitalSignsFilterSearch(search, final)}
                        filterText={VitalSignsFilter.search}
                        final={VitalSignsFilter.final}
                        searchClassName={"InformationInput-TransferList-Search"}
                        className={"InformationInput InformationInput-TransferList"}/>
                </Paper>
            </Grid>
            <Grid className={'Information-Content-Item'} item xs={12} sm={6} md={4} lg={3}>
                <Paper className={'Information-Content-Item-Paper'} elevation={3} style={{ height: 'max-content', width: '560px', boxShadow: 'unset', backgroundColor: 'transparent' }}>

                </Paper>
            </Grid>
        </Grid>
    );
}
//
export default HealthInformation;