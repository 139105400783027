import TextField from '@mui/material/TextField';
import Grid from "@mui/material/Grid";
import * as React from "react";
import {ChangeEvent, useEffect} from "react";
import {TextProps} from "../../utils/types";
import FormControl from "@mui/material/FormControl";


export default function Text({
                                 label, name = "",
                                 value, onChange, disabled,
                                 id = "", className = "", type = 'text',
                                rest
                             }: TextProps) {

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target
        onChange({name, value})
    }

    return (
        <FormControl sx={{m: 1, width: rest?.width || '400px'}}>
            <TextField
                name={name}
                onChange={handleChange}
                disabled={disabled}
                className={`assuta-input ${className}`}
                sx={{width: rest?.width || '400px', '.MuiInputBase-input': {fontSize: '18px'}}}
                id={id ? id + "-outlined-search" : ""}
                inputProps={{style: {fontSize: 16}}}
                InputLabelProps={{style: {fontSize: 16}}}
                label={label ?? "Search field"}
                value={value || ""}
                type={type}/>
        </FormControl>
    )

}
