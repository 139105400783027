import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import _ from 'lodash';
import {TransferListProps, Option} from "../../utils/types";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {Card, CardHeader, Divider} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {ChangeEvent, useEffect} from "react";
import CheckboxInput from "./CheckboxInput";

function not(a: Option[], b: Option[]) {
    return a.filter(aItem => !b.some(bItem => bItem.value === aItem.value));
}

function intersection(a: Option[], b: Option[]) {
    return a.filter(aItem => b.some(bItem => bItem.value === aItem.value));
}


export default function TransferList({
                                         label, options,
                                         selected, onChange, filterText, filterCallback, final,
                                         id = "", className = "", searchClassName = ""
                                     }: TransferListProps) {

    const [checked, setChecked] = React.useState<Option[]>([]);
    const [left, setLeft] = React.useState<Option[]>([]);
    const [rightFilterText, setRightFilterText] = React.useState<string>('');

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, selected);

    useEffect(() => {
        setLeft(options.filter(option => !selected.find(selectedOption => selectedOption.value === option.value)))
    },[options])

    const handleToggle = (options: Option[],value: string) => () => {
        const currentIndex = checked.findIndex(checkedItem => checkedItem.value === value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(options.find(option => option.value === value)!);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleMove = (source: Option[], destination: Option[], itemsToMove: Option[]) => {
        const sourceUpdated = not(source, itemsToMove);
        const destinationUpdated = destination.concat(itemsToMove);

        if (source === left) {
            setLeft(sourceUpdated);
            onChange(destinationUpdated);
        } else {
            setLeft(destinationUpdated);
            onChange(sourceUpdated);
        }
        setChecked(not(checked, itemsToMove));
    };

    const handleAllRight = () => handleMove(left, selected, left);
    const handleCheckedRight = () => handleMove(left, selected, leftChecked);
    const handleCheckedLeft = () => handleMove(selected, left, rightChecked);
    const handleAllLeft = () => handleMove(selected, left, selected);

    const debouncedSearch = _.debounce(async (criteria: string) => {
        await filterCallback(criteria, final);
    }, 300);

    const setFinal = async (input: boolean) => {
        await filterCallback(filterText, input);
    }

    const customList = (items: Option[], filterText: string, filterCallback: (text: string) => void, isSelectedList = false) => {
        const filteredItems = isSelectedList? items.filter(item => item.label.toLowerCase().includes(filterText)) : items;
        const searchLabel = isSelectedList ? `Selected ${label}` : label;
        return (
            <Card className={className} sx={{ height: 250, width: 230 }}>
                <CardHeader
                    sx={{
                        px: 1.4, py: 1.1, width: 230,
                        "& span": {
                            paddingRight: '0',
                            visibility: 'hidden',
                            paddingLeft: '5px',
                            marginRight: '-10px'
                        }
                    }}
                    avatar={
                        <TextField
                            className={searchClassName}
                            InputLabelProps={{
                                sx: {
                                    fontWeight: '1000',
                                    fontSize: '15px',
                                    lineHeight: '1.7'
                                },
                                shrink: true
                            }}
                            sx={{ fontSize: "28px", fontWeight: 1000, width: '208px' }}
                            size={'small'} id="outlined-basic" label={searchLabel} variant="outlined" onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            filterCallback(event.target.value)
                        }} />
                    }
                />
                <Divider />
                <List dense component="div" role="list" style={{ maxHeight: 177, overflow: 'auto' }}>
                    {filteredItems.map((item: Option) => {
                        const labelId = `transfer-list-item-${item.value}-label`;

                        return (
                            <ListItemButton
                                key={String(item.value)}
                                role="listitem"
                                onClick={handleToggle(
                                    items,
                                    String(item.value)
                                )}
                                sx={{ paddingLeft: '2px', paddingRight: '2px' }}
                            >
                                <ListItemIcon sx={{ marginRight: -2 }}>
                                    <Checkbox
                                        checked={checked.some(checkedItem => checkedItem.value === item.value)}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={item.label} />
                            </ListItemButton>
                        );
                    })}
                </List>
            </Card>
        );
    };

    return (
        <FormControl sx={{ m: 1, width: 648 }} className={'TransferList-GridFomControl'}>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    {customList(left, filterText, debouncedSearch)}
                </Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <CheckboxInput name={"final"} label={"Final"} value={final} onChange={async event => await setFinal(event.value)} rest={{width: '90px'}}/>
                        <Button
                            sx={{ my: 0.5, width: '50px', minWidth: '50px' }}
                            variant="outlined"
                            size="small"
                            onClick={handleAllRight}
                            disabled={left.length === 0}
                            aria-label="move all selected"
                        >
                            ≫
                        </Button>
                        <Button
                            sx={{ my: 0.5, width: '50px', minWidth: '50px' }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected selected"
                        >
                            &gt;
                        </Button>
                        <Button
                            sx={{ my: 0.5, width: '50px', minWidth: '50px' }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                        <Button
                            sx={{ my: 0.5, width: '50px', minWidth: '50px' }}
                            variant="outlined"
                            size="small"
                            onClick={handleAllLeft}
                            disabled={selected.length === 0}
                            aria-label="move all left"
                        >
                            ≪
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>{customList(selected, rightFilterText, setRightFilterText, true)}</Grid>
            </Grid>
        </FormControl>
    );
}