import TextField from '@mui/material/TextField';
import * as React from "react";
import {ChangeEvent, useEffect} from "react";
import {TextAreaProps} from "../../utils/types";
import FormControl from "@mui/material/FormControl";


export default function TextArea({
                                 label, name = "",
                                 value, onChange,
                                 id = "", className = "",
                                 rest
                             }: TextAreaProps) {

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target
        onChange({name, value})
    }

    return (
        <FormControl sx={{m: 1, width: rest?.width || '400px'}}>
            <TextField
                multiline
                name={name}
                onChange={handleChange}
                rows={4}
                className={`assuta-input assuta-input-textarea ${className}`}
                sx={{width: rest?.width || '400px', '.MuiInputBase-input': {fontSize: '18px'}}}
                id={id ? id + "-outlined-search" : ""}
                inputProps={{style: {fontSize: 16, height: '120px'}}}
                InputLabelProps={{style: {fontSize: 16}}}
                label={label ?? "Description"}
                value={value || ""}/>
        </FormControl>
    )

}
