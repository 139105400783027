import React, {MouseEventHandler, useEffect, useState} from 'react';
import {Namespace, ResearcherHomeProps} from '../../utils/types';
import {useNavigate} from "react-router-dom";
import rest from "../../services/rest";
import Select from "../../components/inputs/Select";
import ComboBox from "../../components/inputs/ComboBox";



function Home({Namespace, setParams, setColabel, setBtn, setBackBtn}: ResearcherHomeProps ) {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [namespaces, setNamespaces] = React.useState<string[]>([]);

    useEffect(() => {
        if (isLoading) {
            const fetchData = async () => {
                const namespace = await getNameSpaces()
                setNamespaces(namespace)
                setLoading(false);
            }
            setColabel('Namespace Selection')
            setBtn({
                text: 'Next',
                disabled: !Namespace,
                path: '/CatalogManagement/main/researcher/ii',
            })
            setBackBtn({visible: false})
            fetchData()
        }
    }, [isLoading]);

    const getNameSpaces = async () => new Promise<string[]>((resolve, reject) => {
        rest.getNamespaces().then(res => {
            let data: Namespace[] = (res?.data ?? res)
            const namespaces = data.map(namespace => {
                return namespace.Namespace
            })
            resolve(namespaces)
        }).catch(err => reject)
    })

    return (
        <div className={'Information-Content'}>
            <h3>Please select a namespace to continue with:</h3>
            <div id={"welcome-page-actions"}>
                <ComboBox label={'Namespace'} options={
                    namespaces.map(namespace => {return {label: namespace, value: namespace}})
                } onChange={value => {
                    setBtn({disabled: false})
                    setParams('Namespace', String(value))
                }} onSearchChange={console.log} selected={Namespace}/>
            </div>
        </div>

    );
}

export default Home;

