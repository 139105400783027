import React, {useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ComboBox from "../../components/inputs/ComboBox";
import TextArea from "../../components/inputs/TextArea";
import Text from "../../components/inputs/Text";
import {_User, User} from "../../utils/types";

interface UserColProps {
    Namespace?: string;
    Users: User[];
    user: _User;
    setUser: (user: _User) => void;
    SetUserCallback: () => void;
}

const UserCol = ({Namespace, Users, user, setUser,SetUserCallback}: UserColProps) => {
    const [isNew, setIsNew] = React.useState<boolean>(true);

    useEffect(() => {
        console.log({Namespace})
        setUser({})
        setIsNew(true)
    },[Namespace])


    if (!Namespace || Namespace === "new")
        return (
            <Grid className={'Information-Content managment-frame'} container spacing={2} direction="row" justifyContent="space-evenly"
                  alignItems="flex-start">
                <Grid className={'Information-Content-Item'} item xs={12} sm={6} md={4} lg={3}>
                    <Paper className={'Information-Content-Item-Paper'} elevation={3} style={{
                        height: 'max-content',
                        width: '560px',
                        boxShadow: 'unset',
                        backgroundColor: 'transparent'
                    }}>
                        <h3>Select Namespace</h3>
                    </Paper>
                </Grid>
            </Grid>
        )

    return (
        <Grid className={'Information-Content managment-frame'} container spacing={2} direction="row" justifyContent="space-evenly"
              alignItems="flex-start">
            <Grid className={'Information-Content-Item'} item xs={12} sm={6} md={4} lg={3}>
                <Paper className={'Information-Content-Item-Paper'} elevation={3} style={{
                    height: 'max-content',
                    width: '560px',
                    boxShadow: 'unset',
                    backgroundColor: 'transparent'
                }}>
                    <ComboBox label={'Users'} options={
                        Users.map(user => {return {label: user.UserName, value: user.UserName}}).concat(
                            [{label: "Add new", value: "new"}]
                        )
                    } onChange={value => {
                        if (value === 'new') {
                            setUser({})
                            setIsNew(true)
                        }else {
                            const user = (Users.find(user => user.UserName === value) || {})
                            setUser(user)
                            setIsNew(false)
                        }
                    }} onSearchChange={console.log} selected={(Users.find(user_obj => user_obj.UserName === user.UserName))?.UserName || "new"}/>

                    <Text name={'FullName'} disabled={!isNew} label={'FullName'} value={user.FullName || ""} onChange={
                        event => setUser({ FullName: String(event.value)})
                    }/>

                    <Text name={'EMail'} disabled={!isNew} label={'EMail'} value={user.EMail || ""} onChange={
                        event => setUser({ EMail: String(event.value)})
                    }/>

                    <Text name={'Phone'} disabled={!isNew} label={'Phone'} value={user.Phone || ""} onChange={
                        event => setUser({ Phone: String(event.value)})
                    }/>

                </Paper>
            </Grid>
            <Grid className={'Information-Content-Item'} item xs={12} sm={6} md={4} lg={3}>
                <Paper className={'Information-Content-Item-Paper'} elevation={3} style={{
                    height: 'max-content',
                    width: '560px',
                    boxShadow: 'unset',
                    backgroundColor: 'transparent'
                }}>
                    <Text name={'Username'} disabled={!isNew} label={'Username'} value={user.UserName || ""} onChange={
                        event => setUser({ UserName: String(event.value)})
                    }/>
                    <button className={'btn'} disabled={!user.UserName} style={{margin: '8px'}}
                            onClick={event => {
                                SetUserCallback()
                                setIsNew(false)
                            }}>Save</button>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default UserCol;